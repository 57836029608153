import { $post } from '@/api/http.js'
import common from "@/api/common.js"
import tool from '@/util/tool.js'
import router from '../router'

class Question{

    /**
     * 获取问题分类信息
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getQuestionType(page,limit,fn){
        let param = { page,limit,is_top:0}
        $post("getProblemCategoryList",param).then(res=>{
            fn(res.data)
        })
    }

    /**
     * 新增或编辑问题分类信息
     * @param {object}} param 
     * @param {function} fn 
     * @returns 
     */
    addOrEditQuestionType(param,fn){
        if( !tool.returnMessage(param.name,'请输入分类名称')) return
        if( !param.id ) delete param.id
        $post("createOrUpdateProblemCategory",param).then(res=>{
            tool.message("保存成功")
            fn(res.data)
        })
    }

    /**
     * 获取问题列表
     * @param {number} page 
     * @param {number} limit 
     * @param {function} fn 
     */
    getQuestion(page,limit,fn){
        let param = { page,limit }
        common.getDataList("getProblemList",param).then(res=>{
            fn(res)
        })
    }
    
    /**
     * 新增或编辑问题信息
     * @param {object} param 
     * @returns 
     */
    addOrEditQuestion(param){
        if( !tool.returnMessage(param.category_id,'请选择问题分类')) return
        if( !tool.returnMessage(param.problem_title,'请填写问题名称')) return
        if( !tool.returnMessage(param.problem_value,'请填写问题答案')) return
        if( !param.id) delete param.id

        $post("createOrUpdateProblem",param).then(res=>{
            tool.message("保存成功")
            router.push('/set/question/questionList')
        })
    }

    /**
     * 获取问题详情
     * @param {number} id 问题id
     * @param {array} keys 需要的字段
     * @param {function} fn 
     */
    getProblemDetail(id,keys=null,fn){
        $post('getProblemDetail',{id}).then(res=>{
            if(keys ) {
                fn(tool.filterForm(keys,res.data))
                return
            }
            fn(res.data)
        })
    }
}

const questionModel = new Question()
export default questionModel