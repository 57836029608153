<template>
	<div id="questionTypeList" class="el-content">
        <a-button class="mb12" @click="editType(0)" v-has="{action:'wxapp_set_question_edit',plat:0}" type="primary">
			<i class="ri-add-line"></i>新增分类
		</a-button>
		<a-table row-key="id" :pagination="false" :data-source="list" :columns="[
            {title:'ID',dataIndex:'id'},
            {title:'名称',dataIndex:'name'},
            {title:'状态',dataIndex:'category_status',slots:{customRender:'category_status'}},
            {title:'排序',dataIndex:'rank'},
            {title:'最后更新时间',dataIndex:'update_time'},
            {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
        ]" class="mt12">
            <template #category_status="{record}">
                <a-tag color="#00CC66" v-if="record.category_status ==1 ">显示</a-tag>
                <a-tag color="#999" v-if="record.category_status ==0 ">隐藏</a-tag>
            </template>
            <template #action="{record}">
                <a-space>
                    <kd-button type="primary" title="编辑" icon="ri-edit-line" v-has="{action:'wxapp_set_question_edit'}"  @click="editType(record)"></kd-button>
                    <kd-button type="danger" title="删除" icon="ri-delete-bin-5-line" v-has="{action:'wxapp_set_question_type_del'}"  @click="deleteQuestionType(record.id)"></kd-button>
                </a-space>
            </template>
        </a-table>
        <a-modal v-model:visible="show.edit" title="添加/编辑分类" @ok="saveType" width="600px" @cancel="show.edit = false">
			<a-form :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
				<a-form-item label="名称">
					<a-input placeholder="分类名称" v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="排序">
					<a-input v-model:value="form.rank"></a-input>
				</a-form-item>
				<a-form-item label="是否显示">
					<kd-switch :value="form.category_status" @change="e=>form.category_status = e" :inactive-value="0" :active-value="1"></kd-switch>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import questionModel from '@/api/question.js'
import common from "@/api/common.js"
export default {
	setup(){
		const _d =reactive({
			list:[],
			show:{
				edit:false,
			},
			form:null
		})
		getQuestionType()
		function getQuestionType(){
			questionModel.getQuestionType(1,999,res=>{
				_d.list = res
			})
		}

		function editType(row){
			_d.form = {
				id:row ? row.id :0,
				pid:row ? row.pid :0,
				name:row ? row.name :"",
				rank:row ? row.rank :99,
				category_status:row ? row.category_status :1,
			}
			_d.show.edit = true
		}

		function saveType(){
			questionModel.addOrEditQuestionType(_d.form,()=>{
				getQuestionType()
				_d.show.edit = false
			})
		}

		const deleteQuestionType = (id)=> common.deleteDataList(id,21,'确认删除该分类吗？').then(()=>{
			getQuestionType()
		})

		return{
			...toRefs(_d),
			getQuestionType,
			editType,
			saveType,
			deleteQuestionType
		}
	},
}
</script>
<style></style>